import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";


import { Container, Wrapper, Row, Box } from "../../components/util";
import { Breadcrumb, PartnerHelpFooter } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import PartnerStatusLevels from "../../images/partner-toolkit/Partner_Status_Levels.png";


const PartnerStatusLevelsImg = styled.img`
  width: 850px;
  height: 850px;
  margin-left: auto;
  margin-right: auto;
`;

const PartnerDiscountCard = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 25px;
  border-radius: 25px;
  min-width: 275px;
  
  h4 {
    padding-bottom: 10px;
  }
`;

const SubOptions = styled(Box)`
  display: flex;
  flex-direction: column;

  li {
    text-align: left;
  }
`;

const PartnerSupportRow = styled(Row)`
  a {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    background: #00ADEF1A;
    border-radius: 25px;
    font-weight: 600;
    text-decoration: none;
    min-width: 240px;
  }
  h5 {
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
  }

  @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
    align-items: center;

    > div {
      margin-bottom: 15px;
    }
  }
`;

const ProductCardsRow = styled(Row)`
    padding-top: 20px;
    padding-bottom: 20px;

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      align-items: center;
      > div {
        margin-bottom: 20px;
      }
    }

`;


const Certification = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="Certification & Partner Status | Partner Resources | FlexiTime Partner"
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Breadcrumb
        parentPage="Partner Resources"
        parentPageLink="/partner-resources"
        currentPage="Certification & Partner Status"
      />
      <Container className="border-bottom">
        <Wrapper stackGap={20}>
          <Box stackGap={70} className="-textCenter">
            <h1 className="-halfSizeCenter">Increase your status to save and earn more</h1>
          </Box>
        </Wrapper>
      </Container>

      <Container>
        <Wrapper maxWidth={1000}>
          <Box stackGap={10} className="-textCenter">
            <h4>
              The FlexiTime Partner Agreement is designed to reward our Partners for the commitment they make towards promoting our Products to their Clients and maintaining an ongoing knowledge of our systems to provide first-level support.
            </h4>
          </Box>
        </Wrapper>
      </Container>

      <Container className="-textCenter">
        <div style={{
          background: '#00ADEF1A',
          paddingTop: '50px',
          paddingBottom: '50px'
        }}>
          <div className="-halfSizeCenter">
            <h2 className="-fontOrange">Get Certified</h2>
            <h4 className="-halfSizeCenter" style={{
              paddingTop: '20px',
              paddingBottom: '20px'
            }}>To get started, earn a certification in Payhero, Droppah and Karmly to show your clients that you know your stuff</h4>

            <h4>Email support@flexitime.works with the subject "Certified"</h4>
          </div>
        </div>
      </Container>

      <Container className="-textCenter">
        <Wrapper maxWidth={1000}>
          <div>
            <h2 className="-fontOrange">Partner Status Levels</h2>
            <h4 className="-halfSizeCenter" style={{
              paddingTop: '20px',
              paddingBottom: '20px'
            }}>The Partner Status Levels will be reviewed annually or upon request by the Partner.</h4>


          </div>
          <PartnerStatusLevelsImg
            src={PartnerStatusLevels}
            alt="Partner Status" />
        </Wrapper>
      </Container>

      <Container className="-textCenter secondary">
        <Wrapper stackGap={20}>
          <div className="-halfSizeCenter">
            <h2>Pricing Discounts</h2>
            <h4 style={{
              paddingTop: '20px',
              paddingBottom: '20px'
            }}>For all Products, the following partner discounts apply at the Partner Status Levels.</h4>
          </div>
          <ProductCardsRow>
            <PartnerDiscountCard backgroundColor={'#D7C843'}>
              <div>
                <h4>Gold Partner</h4>
                <h2>25% discount</h2>
              </div>
            </PartnerDiscountCard>
            <PartnerDiscountCard backgroundColor={'#A9AAAA'}>
              <div>
                <h4>Silver Partner</h4>
                <h2>20% discount</h2>
              </div>
            </PartnerDiscountCard>
            <PartnerDiscountCard backgroundColor={'#B76D2C'}>
              <div>
                <h4>Bronze Partner</h4>
                <h2>15% discount</h2>
              </div>
            </PartnerDiscountCard>
            <PartnerDiscountCard backgroundColor={'#39627C'}>
              <div>
                <h4>Standard Partner</h4>
                <h2>No discount</h2>
              </div>
            </PartnerDiscountCard>
          </ProductCardsRow>
        </Wrapper>
      </Container>

      <Container>
        <Wrapper maxWidth={1000} stackGap={50}>
          <SubOptions stackGap={10} className="-textCenter">
            <h2 className="-fontOrange">Subscription options</h2>
            <ol css={{ marginBottom: "20px", marginTop: "20px" }}>
              <li>
                Partner pays subscription and receives a discount dependent on the Partner Status.
              </li>
              <li>
                Client pays subscription and receives 10% discount.
              </li>
              <li>
                Client pays subscription and Partner receives 10% revenue share.
              </li>
            </ol>
            <h4>
              Where the Client pays the subscription the Partner has the option of receiving revenue share or passing the 10% discount on to the Client. The Partner must choose the preferred Subscription Option from 2 or 3 above to be applied to all Clients.
            </h4>
          </SubOptions>
        </Wrapper>
      </Container>

      <Container className="-textCenter">
        <Wrapper stackGap={50}>
          <Box stackGap={20}>
            <h2 className="-textCenter -fontOrange" style={{
              marginBottom: '40px',
            }}>Important Documents</h2>
            <PartnerSupportRow>
              <div>
                <a
                  href="https://www.flexitime.works/partner-resources/code-of-conduct"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PARTNER CODE OF CONDUCT
                </a>
              </div>
              <div>
                <a
                  href="https://www.flexitime.works/partner-resources/partner-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PARTNER AGREEMENT
                </a>
              </div>
              <div>
                <a
                  href="https://www.flexitime.works/security"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DATA SECURITY
                </a>
              </div>
            </PartnerSupportRow>
          </Box>
        </Wrapper>
      </Container>

      <PartnerHelpFooter></PartnerHelpFooter>

    </Layout>
  );
};

export default Certification;
